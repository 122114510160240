<template>
    <div class="profile-form">
        <upload-image-modal @uploaded-image="uploadedImage" />
        <creator-program-modal />
        <email-change-modal @is-loading="(value) => is_loading = value" />
        <fullscreen-loader v-if="is_loading" />
        <ValidationObserver v-slot="{ handleSubmit }" ref="profile_form" slim>
            <form novalidate @submit.prevent="handleSubmit(submitProfile)">
                <div class="profile-cover" :style="`background-image: url(${profileCover})`">
                    <div class="change-cover">
                        <img src="@assets/img/icons/change-image.svg" alt="Camera">
                    </div>
                    <button type="button" class="upload-image-button" @click="$modal.show('upload-image-modal', { fieldName: PROFILE_COVER_FIELD_NAME, stencilSize: { width: 750, height: 360 } })" />
                </div>
                <div class="form-content">
                    <div class="profile-picture">
                        <div class="change-picture">
                            <img class="camera" src="@assets/img/icons/camera.svg" alt="Camera">
                            <img :src="profilePicture" class="user-picture">
                        </div>
                        <span>Set a profile picture</span>
                        <button class="upload-image-button" type="button" @click="$modal.show('upload-image-modal', { fieldName: PROFILE_PICTURE_FIELD_NAME, stencilType: 'circle-stencil' })" />
                    </div>
                    <ValidationProvider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="user name"
                        rules="required"
                    >
                        <div :class="{ 'required' : errors.length }" class="form-group">
                            <label for="username">Pick your username</label>
                            <div class="form-control-container">
                                <span class="prepended-char">@</span>
                                <input
                                    id="username"
                                    v-model="displayname"
                                    type="text"
                                    class="form-control"
                                >
                            </div>
                            <div class="form-group-footer">
                                <span v-if="errors.length" class="field-error">The username is required. Only letters, numbers, underscored and periods are allowed.</span>
                                <span :class="{ 'limit-warning' : counterLimitWarning(displayname.length, charactersLimits.displayname), 'limit-reached' : counterLimitReached(displayname.length, charactersLimits.displayname) }" class="characters-counter">
                                    {{ displayname.length }}/{{ charactersLimits.displayname }}
                                </span>
                            </div>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="bio"
                    >
                        <div :class="{ 'required' : errors.length }" class="form-group">
                            <label for="bio">Email</label>
                            <span v-if="errors.length">({{ errors[0] }})</span>
                            <input
                                id="email"
                                v-model="email"
                                class="form-control"
                                rows="2"
                                placeholder="Email"
                            >
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <div class="col">
                            <ValidationProvider>
                                <div class="form-group">
                                    <label for="firstname">What is your full name?</label>
                                    <input
                                        id="firstname"
                                        v-model="firstname"
                                        type="text"
                                        class="form-control"
                                        placeholder="First"
                                    >
                                    <div class="form-group-footer">
                                        <span :class="{ 'limit-warning' : counterLimitWarning(firstname.length, charactersLimits.firstname), 'limit-reached' : counterLimitReached(firstname.length, charactersLimits.firstname) }" class="characters-counter">
                                            {{ firstname.length }}/{{ charactersLimits.firstname }}
                                        </span>
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <ValidationProvider>
                                <div class="form-group">
                                    <label for="lastname" />
                                    <input
                                        id="lastname"
                                        v-model="lastname"
                                        type="text"
                                        class="form-control"
                                        placeholder="Last"
                                    >
                                    <div class="form-group-footer">
                                        <span :class="{ 'limit-warning' : counterLimitWarning(lastname.length, charactersLimits.lastname), 'limit-reached' : counterLimitReached(lastname.length, charactersLimits.lastname) }" class="characters-counter">
                                            {{ lastname.length }}/{{ charactersLimits.lastname }}
                                        </span>
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <ValidationProvider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="bio"
                    >
                        <div :class="{ 'required' : errors.length }" class="form-group">
                            <label for="bio">Bio</label>
                            <span v-if="errors.length">({{ errors[0] }})</span>
                            <textarea
                                id="bio"
                                ref="textarea"
                                v-model="description"
                                class="form-control"
                                rows="1"
                                placeholder="Add your bio"
                                @focus="resize"
                                @keyup="resize"
                            />
                            <div class="form-group-footer">
                                <span :class="{ 'limit-warning' : counterLimitWarning(description ? description.length : 0, charactersLimits.description), 'limit-reached' : counterLimitReached(description ? description.length : 0, charactersLimits.description) }" class="characters-counter">
                                    {{ description ? description.length : 0 }}/{{ charactersLimits.description }}
                                </span>
                            </div>
                        </div>
                    </ValidationProvider>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
const userProfile = {
    firstname: "",
    lastname: "",
    displayname: "",
    description: "",
    email: ""
}

export default {
    name: "",
    components: {
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader.vue"),
        uploadImageModal: () => import(/* webpackChunkName: "upload-image-modal" */ "@c/organisms/modals/upload-image-modal"),
        CreatorProgramModal: () => import(/* webpackChunkName: "creator-program" */ "@/components/organisms/modals/creator-program"),
        EmailChangeModal: () => import(/* webpackChunkName: "creator-program" */ "@/components/organisms/modals/email-change")
    },
    data() {
        return {
            PROFILE_PICTURE_FIELD_NAME: "photo",
            PROFILE_COVER_FIELD_NAME: "cover_image",
            profilePicture: "",
            profileCover: "",
            userProfile: {
                firstname: "",
                lastname: "",
                displayname: "",
                description: "",
                email: "",
                photo: {}
            },
            charactersLimits: {
                firstname: 20,
                lastname: 20,
                displayname: 20,
                description: 160
            },
            currentEmail: "",
            is_loading: false
        };
    },
    computed: {},
    beforeCreate() {
        for (const key in userProfile) {
            this.$options.computed[key] = {
                get() {
                    return this.userProfile[key];
                },
                set(newValue) {
                    this.userProfile[key] = newValue.substring(0, this.charactersLimits[key]);
                }
            };
        }
    },
    created() {
        Object.assign(this.userProfile, this.$store.state.User.data);
        this.profilePicture = this.$store.state.User.data.photo.url;
        this.profileCover = this.$store.state.User.data.cover_image?.url || require("@assets/img/profile-cover-placeholder.jpg");
    },
    mounted() {
        this.currentEmail = this.userProfile.email;
    },
    methods: {
        resize() {
            const { textarea } = this.$refs;
            textarea.style.height = textarea.scrollHeight - 6 + "px";
        },
        submitProfile() {
            this.$refs.profile_form.validate().then(async success => {

                if (!success) {
                    return;
                }

                if (this.currentEmail !== this.userProfile.email) {
                    const { data: { found } } = await axios.post("/validate/user-email", {
                        email: this.userProfile.email
                    });

                    if (found === false) {
                        this.$modal.show("email-change");
                    }
                }

                const { firstname, lastname, displayname, description, email } = this.userProfile;
                const data = {
                    firstname,
                    lastname,
                    displayname,
                    description,
                    email
                }

                if (this.userProfile.files) {
                    data.files = this.userProfile.files;
                }

                axios.put(`/users/${this.userProfile.id}`, data).then(({ data }) => {
                    this.$store.dispatch("User/setData", data);
                    this.$emit("saved-user-profile", data);
                }).catch((error) => {
                    this.$notify({
                        group: "app-notifications",
                        type: "error",
                        text: error.response.data.errors.message,
                        duration: 5000
                    });
                    console.log(error.response.data.errors.message);
                })
            })
        },
        counterLimitWarning(value, limit) {
            const warningPercentage = 15;
            return Boolean(value >= (limit - ((warningPercentage / 100) * limit)));
        },
        counterLimitReached(value, limit) {
            return Boolean(value === limit);
        },
        uploadedImage(uploadedImage) {
            if (!this.userProfile.hasOwnProperty("files")) {
                this.userProfile.files = [];
            }
            if (uploadedImage.field_name == this.PROFILE_PICTURE_FIELD_NAME) {
                this.setUploadedProfilePicture(uploadedImage)
            } else {
                this.setUploadedProfileCover(uploadedImage);
            }
        },
        setUploadedProfilePicture(uploadedImage) {
            this.profilePicture = uploadedImage.url;
            this.userProfile.files.push({
                id: this.userProfile.photo?.id || null,
                filesystem_id: uploadedImage.id,
                field_name: this.PROFILE_PICTURE_FIELD_NAME
            })

            // While updating the profile picture we need to send back the existing profile cover
            // because if we don't do that the backend will delete it, don't even ask...
            if (this.userProfile.cover_image && this.userProfile.cover_image.filesystem_id) {
                this.userProfile.files.push({
                    id: this.userProfile.cover_image.id,
                    filesystem_id: this.userProfile.cover_image.filesystem_id,
                    field_name: this.PROFILE_COVER_FIELD_NAME
                })
            }
        },
        setUploadedProfileCover(uploadedImage) {
            this.profileCover = uploadedImage.url;
            this.userProfile.files.push({
                id: this.userProfile.profile_image?.id || null,
                filesystem_id: uploadedImage.id,
                field_name: this.PROFILE_COVER_FIELD_NAME
            })

            // While updating the profile cover we need to send back the existing profile picture
            // because if we don't do that the backend will delete it, don't even ask...
            if (this.userProfile.photo && this.userProfile.photo.filesystem_id) {
                this.userProfile.files.push({
                    id: this.userProfile.photo.id,
                    filesystem_id: this.userProfile.photo.filesystem_id,
                    field_name: this.PROFILE_PICTURE_FIELD_NAME
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-form {
    .profile-cover {
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(180deg, rgba(28, 28, 30, 0.2) 15.62%, rgba(28, 28, 30, 0.6) 64.06%, #111111 100%);
        }

        .change-cover {
            display: flex;
            flex-direction: column;
            position: relative;
        }
    }

    .form-content {
        margin-top: -85px;

        .profile-picture {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            position: relative;
            margin-bottom: 20px;

            .change-picture {
                width: 120px;
                height: 120px;
                border: 2px solid black;
                color: #242426;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #242426;
                overflow: hidden;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, .6);
                }

                .camera {
                    position: absolute;
                    background-color: rgba(0, 0, 0, .3);
                }

                .user-picture {
                    width: 100%;
                }
            }

            span {
                display: block;
                font-weight: 400;
                color: #C6C6C6;
                margin-top: 10px;
            }
        }

        .form-group {
            margin-bottom: 25px;

            label {
                min-height: 16px;
            }

            @media(max-width: $sm) {
                label {
                    font-size: 15.5px;
                    min-height: 13px;
                }
            }

            .form-control-container {
                display: flex;
                position: relative;

                .prepended-char {
                    position: absolute;
                    font-size: 18px;
                    top: 50%;
                    transform: translateY(calc(-50% - 2px));
                    line-height: 1.5;
                }

                .form-control {
                    padding-left: 15px;
                }
            }
        }
    }
}
</style>
